import { type ChainInfo } from '@safe-global/safe-gateway-typescript-sdk'
import { FEATURES, GAS_PRICE_TYPE, RPC_AUTHENTICATION } from '@safe-global/safe-gateway-typescript-sdk'

export const chains: ChainInfo[] = [
  {
    chainId: '1',
    chainName: 'Ethereum',
    description: 'The main Ethereum network',
    l2: false,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/1/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-mainnet.safe.global',
    blockExplorerUriTemplate: {
      address: 'https://etherscan.io/address/{{address}}',
      txHash: 'https://etherscan.io/tx/{{txHash}}',
      api: 'https://api.etherscan.io/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['NONE', 'tally', 'walletConnect'],
    ensRegistryAddress: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
    features: [
      FEATURES.CONTRACT_INTERACTION,
      // FEATURES.DEFAULT_TOKENLIST,
      FEATURES.DOMAIN_LOOKUP,
      FEATURES.EIP1271,
      FEATURES.EIP1559,
      FEATURES.ERC721,
      // FEATURES.MOONPAY_MOBILE,
      // FEATURES.RISK_MITIGATION,
      FEATURES.SAFE_APPS,
      FEATURES.SAFE_TX_GAS_OPTIONAL,
      FEATURES.SPENDING_LIMIT,
      FEATURES.TX_SIMULATION,
    ],
    gasPrice: [
      {
        type: GAS_PRICE_TYPE.ORACLE,
        uri: 'https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=JNFAU892RF9TJWBU3EV7DJCPIWZY8KEMY1',
        gasParameter: 'FastGasPrice',
        gweiFactor: '1000000000.000000000',
      },
    ],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://cloudflare-eth.com',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.API_KEY_PATH,
      value: 'https://mainnet.infura.io/v3/',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.API_KEY_PATH,
      value: 'https://mainnet.infura.io/v3/',
    },
    shortName: 'eth',
    theme: {
      textColor: '#001428',
      backgroundColor: '#DDDDDD',
    },
  },
  {
    chainId: '100',
    chainName: 'Gnosis Chain',
    description: '',
    l2: true,
    nativeCurrency: {
      name: 'xDai',
      symbol: 'XDAI',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/100/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-gnosis-chain.safe.global',
    blockExplorerUriTemplate: {
      address: 'https://gnosisscan.io/address/{{address}}',
      txHash: 'https://gnosisscan.io/tx/{{txHash}}/',
      api: 'https://api.gnosisscan.io/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['keystone', 'NONE', 'opera', 'operaTouch', 'tally', 'trust', 'walletConnect'],
    ensRegistryAddress: undefined,
    features: [
      FEATURES.CONTRACT_INTERACTION,
      FEATURES.EIP1271,
      FEATURES.EIP1559,
      FEATURES.ERC721,
      // FEATURES.RELAYING,
      // FEATURES.RELAYING_MOBILE,
      FEATURES.SAFE_APPS,
      FEATURES.SAFE_TX_GAS_OPTIONAL,
      FEATURES.SPENDING_LIMIT,
      FEATURES.TX_SIMULATION,
      // FEATURES.WEB3AUTH_CREATE_SAFE,
    ],
    gasPrice: [
      {
        type: GAS_PRICE_TYPE.FIXED,
        weiValue: '4000000000',
      },
    ],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://rpc.ankr.com/gnosis',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://rpc.ankr.com/gnosis',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://rpc.ankr.com/gnosis',
    },
    shortName: 'gno',
    theme: {
      textColor: '#ffffff',
      backgroundColor: '#48A9A6',
    },
  },
  {
    chainId: '137',
    chainName: 'Polygon',
    description: 'L2 chain (MATIC)',
    l2: true,
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/137/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-polygon.safe.global',
    blockExplorerUriTemplate: {
      address: 'https://polygonscan.com/address/{{address}}',
      txHash: 'https://polygonscan.com/tx/{{txHash}}',
      api: 'https://api.polygonscan.com/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['keystone', 'NONE', 'opera', 'operaTouch', 'tally', 'trezor', 'trust', 'walletConnect'],
    ensRegistryAddress: undefined,
    features: [
      FEATURES.CONTRACT_INTERACTION,
      // FEATURES.DEFAULT_TOKENLIST,
      FEATURES.EIP1271,
      FEATURES.ERC721,
      // FEATURES.MOONPAY_MOBILE,
      // FEATURES.RISK_MITIGATION,
      FEATURES.SAFE_APPS,
      FEATURES.SAFE_TX_GAS_OPTIONAL,
      FEATURES.SPENDING_LIMIT,
      FEATURES.TX_SIMULATION,
    ],
    gasPrice: [
      {
        type: GAS_PRICE_TYPE.ORACLE,
        uri: 'https://gasstation.polygon.technology/v2',
        gasParameter: 'estimatedBaseFee',
        gweiFactor: '1000000000.000000000',
      },
    ],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://polygon-rpc.com',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.API_KEY_PATH,
      value: 'https://polygon-mainnet.infura.io/v3/',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.API_KEY_PATH,
      value: 'https://polygon-mainnet.infura.io/v3/',
    },
    shortName: 'matic',
    theme: {
      textColor: '#ffffff',
      backgroundColor: '#8248E5',
    },
  },
  {
    chainId: '56',
    chainName: 'BNB Smart Chain',
    description: '',
    l2: true,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/56/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-bsc.safe.global',
    blockExplorerUriTemplate: {
      address: 'https://bscscan.com/address/{{address}}',
      txHash: 'https://bscscan.com/tx/{{txHash}}',
      api: 'https://api.bscscan.com/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['keystone', 'NONE', 'opera', 'operaTouch', 'tally', 'trust', 'walletConnect'],
    ensRegistryAddress: undefined,
    features: [
      FEATURES.CONTRACT_INTERACTION,
      // FEATURES.DEFAULT_TOKENLIST,
      FEATURES.EIP1271,
      FEATURES.ERC721,
      // FEATURES.MOONPAY_MOBILE,
      // FEATURES.RISK_MITIGATION,
      FEATURES.SAFE_APPS,
      FEATURES.SAFE_TX_GAS_OPTIONAL,
      FEATURES.SPENDING_LIMIT,
      FEATURES.TX_SIMULATION,
    ],
    gasPrice: [],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://bsc-dataseed.binance.org/',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://bsc-dataseed.binance.org/',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://bsc-dataseed.binance.org/',
    },
    shortName: 'bnb',
    theme: {
      textColor: '#001428',
      backgroundColor: '#F0B90B',
    },
  },
  {
    chainId: '42161',
    chainName: 'Arbitrum',
    description: '',
    l2: true,
    nativeCurrency: {
      name: 'AETH',
      symbol: 'AETH',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/42161/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-arbitrum.safe.global',
    blockExplorerUriTemplate: {
      address: 'https://arbiscan.io/address/{{address}}',
      txHash: 'https://arbiscan.io/tx/{{txHash}}',
      api: 'https://api.arbiscan.io/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['keystone', 'ledger', 'NONE', 'opera', 'operaTouch', 'tally', 'trust', 'walletConnect'],
    ensRegistryAddress: undefined,
    features: [
      FEATURES.CONTRACT_INTERACTION,
      FEATURES.EIP1271,
      FEATURES.ERC721,
      // FEATURES.MOONPAY_MOBILE,
      // FEATURES.RISK_MITIGATION,
      FEATURES.SAFE_APPS,
      FEATURES.SAFE_TX_GAS_OPTIONAL,
      FEATURES.TX_SIMULATION,
    ],
    gasPrice: [],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://arb1.arbitrum.io/rpc',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://arb1.arbitrum.io/rpc',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://arb1.arbitrum.io/rpc',
    },
    shortName: 'arb1',
    theme: {
      textColor: '#ffffff',
      backgroundColor: '#28A0F0',
    },
  },
  {
    chainId: '1313161554',
    chainName: 'Aurora',
    description: '',
    l2: true,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/1313161554/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-aurora.safe.global',
    blockExplorerUriTemplate: {
      address: 'https://aurorascan.dev/address/{{address}}/transactions',
      txHash: 'https://aurorascan.dev/tx/{{txHash}}/',
      api: 'https://aurorascan.dev/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: [
      'coinbase',
      'keystone',
      'ledger',
      'NONE',
      'opera',
      'operaTouch',
      'tally',
      'trezor',
      'trust',
      'walletConnect',
    ],
    ensRegistryAddress: undefined,
    features: [
      FEATURES.CONTRACT_INTERACTION,
      // FEATURES.DEFAULT_TOKENLIST,
      FEATURES.EIP1271,
      FEATURES.ERC721,
      FEATURES.SAFE_APPS,
      FEATURES.SAFE_TX_GAS_OPTIONAL,
    ],
    gasPrice: [],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://mainnet.aurora.dev',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://mainnet.aurora.dev',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://mainnet.aurora.dev',
    },
    shortName: 'aurora',
    theme: {
      textColor: '#ffffff',
      backgroundColor: '#78D64B',
    },
  },
  {
    chainId: '8453',
    chainName: 'Base',
    description: '',
    l2: true,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/8453/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-base.safe.global',
    blockExplorerUriTemplate: {
      address: 'https://basescan.org/address/{{address}}',
      txHash: 'https://basescan.org/tx/{{txHash}}',
      api: 'https://basescan.org/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['coinbase', 'keystone', 'NONE', 'opera', 'operaTouch', 'tally', 'trust', 'walletConnect'],
    ensRegistryAddress: undefined,
    features: [
      FEATURES.CONTRACT_INTERACTION,
      FEATURES.EIP1271,
      FEATURES.EIP1559,
      FEATURES.ERC721,
      FEATURES.SAFE_APPS,
      FEATURES.SAFE_TX_GAS_OPTIONAL,
    ],
    gasPrice: [
      {
        type: GAS_PRICE_TYPE.FIXED_1559,
        maxFeePerGas: '150000000',
        maxPriorityFeePerGas: '100000',
      },
    ],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://developer-access-mainnet.base.org',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.API_KEY_PATH,
      value: 'https://base-mainnet.infura.io/v3/',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.API_KEY_PATH,
      value: 'https://base-mainnet.infura.io/v3/',
    },
    shortName: 'base',
    theme: {
      textColor: '#ffffff',
      backgroundColor: '#0052FF',
    },
  },
  {
    chainId: '1101',
    chainName: 'Polygon zkEVM',
    description: 'Polygon zkEVM',
    l2: true,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/1101/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-zkevm.safe.global/',
    blockExplorerUriTemplate: {
      address: 'https://zkevm.polygonscan.com/address/{{address}}',
      txHash: 'https://zkevm.polygonscan.com/tx/{{txHash}}',
      api: 'https://api-zkevm.polygonscan.com/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['coinbase', 'keystone', 'NONE', 'opera', 'operaTouch', 'tally', 'trust', 'walletConnect'],
    ensRegistryAddress: undefined,
    features: [FEATURES.CONTRACT_INTERACTION, FEATURES.ERC721, FEATURES.SAFE_APPS, FEATURES.SPENDING_LIMIT],
    gasPrice: [],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://zkevm-rpc.com',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://zkevm-rpc.com',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://zkevm-rpc.com',
    },
    shortName: 'zkevm',
    theme: {
      textColor: '#000000',
      backgroundColor: '#F5F0FD',
    },
  },
  {
    chainId: '324',
    chainName: 'zkSync Era Mainnet',
    description: '',
    l2: true,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/324/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-zksync.safe.global/',
    blockExplorerUriTemplate: {
      address: 'https://explorer.zksync.io/address/{{address}}',
      txHash: 'https://explorer.zksync.io/tx/{{txHash}}',
      api: 'https://api.explorer.zksync.io/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['coinbase', 'keystone', 'NONE', 'opera', 'operaTouch', 'tally', 'trust', 'walletConnect'],
    ensRegistryAddress: undefined,
    features: [FEATURES.SAFE_APPS],
    gasPrice: [],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://mainnet.era.zksync.io',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://mainnet.era.zksync.io',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://mainnet.era.zksync.io',
    },
    shortName: 'zksync',
    theme: {
      textColor: '#ffffff',
      backgroundColor: '#000000',
    },
  },
  {
    chainId: '43114',
    chainName: 'Avalanche',
    description: '',
    l2: true,
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/43114/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-avalanche.safe.global',
    blockExplorerUriTemplate: {
      address: 'https://snowtrace.io/address/{{address}}',
      txHash: 'https://snowtrace.io/tx/{{txHash}}',
      api: 'https://api.snowtrace.io/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['keystone', 'ledger', 'NONE', 'opera', 'operaTouch', 'tally', 'trust', 'walletConnect'],
    ensRegistryAddress: undefined,
    features: [
      FEATURES.CONTRACT_INTERACTION,
      FEATURES.EIP1271,
      FEATURES.EIP1559,
      FEATURES.ERC721,
      // FEATURES.MOONPAY_MOBILE,
      // FEATURES.RISK_MITIGATION,
      FEATURES.SAFE_APPS,
      FEATURES.SAFE_TX_GAS_OPTIONAL,
      FEATURES.SPENDING_LIMIT,
      FEATURES.TX_SIMULATION,
    ],
    gasPrice: [],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://api.avax.network/ext/bc/C/rpc',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://api.avax.network/ext/bc/C/rpc',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://api.avax.network/ext/bc/C/rpc',
    },
    shortName: 'avax',
    theme: {
      textColor: '#ffffff',
      backgroundColor: '#000000',
    },
  },
  {
    chainId: '42220',
    chainName: 'Celo',
    description: '',
    l2: false,
    nativeCurrency: {
      name: 'CELO',
      symbol: 'CELO',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/42220/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-celo.safe.global',
    blockExplorerUriTemplate: {
      address: 'https://explorer.celo.org/mainnet/address/{{address}}',
      txHash: 'https://explorer.celo.org/mainnet/tx/{{txHash}}',
      api: 'https://explorer.celo.org/mainnet/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['coinbase', 'NONE', 'opera', 'operaTouch', 'tally', 'trust', 'walletConnect'],
    ensRegistryAddress: undefined,
    features: [
      FEATURES.EIP1271,
      FEATURES.EIP1559,
      FEATURES.ERC721,
      // FEATURES.MOONPAY_MOBILE,
      FEATURES.SAFE_APPS,
      FEATURES.SPENDING_LIMIT,
    ],
    gasPrice: [],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://rpc.ankr.com/celo',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://rpc.ankr.com/celo',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://rpc.ankr.com/celo',
    },
    shortName: 'celo',
    theme: {
      textColor: '#000000',
      backgroundColor: '#FCFF52',
    },
  },
  {
    chainId: '10',
    chainName: 'Optimism',
    description: '',
    l2: true,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'OETH',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/10/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-optimism.safe.global',
    blockExplorerUriTemplate: {
      address: 'https://optimistic.etherscan.io/address/{{address}}',
      txHash: 'https://optimistic.etherscan.io/tx/{{txHash}}',
      api: 'https://api-optimistic.etherscan.io/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['keystone', 'NONE', 'opera', 'operaTouch', 'tally', 'trust', 'walletConnect'],
    ensRegistryAddress: undefined,
    features: [
      FEATURES.CONTRACT_INTERACTION,
      // FEATURES.DEFAULT_TOKENLIST,
      FEATURES.EIP1271,
      FEATURES.EIP1559,
      FEATURES.ERC721,
      // FEATURES.MOONPAY_MOBILE,
      FEATURES.SAFE_APPS,
      FEATURES.SAFE_TX_GAS_OPTIONAL,
      FEATURES.TX_SIMULATION,
    ],
    gasPrice: [
      {
        type: GAS_PRICE_TYPE.FIXED_1559,
        maxFeePerGas: '100000000',
        maxPriorityFeePerGas: '100000',
      },
    ],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://mainnet.optimism.io/',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.API_KEY_PATH,
      value: 'https://optimism-mainnet.infura.io/v3/',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.API_KEY_PATH,
      value: 'https://optimism-mainnet.infura.io/v3/',
    },
    shortName: 'oeth',
    theme: {
      textColor: '#ffffff',
      backgroundColor: '#F01A37',
    },
  },
  {
    chainId: '84531',
    chainName: 'Base Goerli Testnet',
    description: '',
    l2: true,
    nativeCurrency: {
      name: 'Base Goerli Ether',
      symbol: 'bGOR',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/84531/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-base-testnet.safe.global/',
    blockExplorerUriTemplate: {
      address: 'https://goerli.basescan.org/address/{{address}}',
      txHash: 'https://goerli.basescan.org/tx/{{txHash}}',
      api: 'https://api-goerli.basescan.org/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['coinbase', 'keystone', 'NONE', 'opera', 'operaTouch', 'tally', 'trust', 'walletConnect'],
    ensRegistryAddress: undefined,
    features: [
      FEATURES.CONTRACT_INTERACTION,
      FEATURES.EIP1271,
      FEATURES.EIP1559,
      FEATURES.ERC721,
      FEATURES.SAFE_APPS,
      FEATURES.SAFE_TX_GAS_OPTIONAL,
    ],
    gasPrice: [],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://goerli.base.org',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://goerli.base.org',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://goerli.base.org',
    },
    shortName: 'base-gor',
    theme: {
      textColor: '#FFFFFF',
      backgroundColor: '#0000FF',
    },
  },
  {
    chainId: '5',
    chainName: 'Goerli',
    description: 'Ethereum Testnet Görli',
    l2: true,
    nativeCurrency: {
      name: 'Goerli Ether',
      symbol: 'GOR',
      decimals: 18,
      logoUri: 'https://safe-transaction-assets.safe.global/chains/5/currency_logo.png',
    },
    transactionService: 'https://safe-transaction-goerli.safe.global/',
    blockExplorerUriTemplate: {
      address: 'https://goerli.etherscan.io/address/{{address}}',
      txHash: 'https://goerli.etherscan.io/tx/{{txHash}}',
      api: 'https://api-goerli.etherscan.io/api?module={{module}}&action={{action}}&address={{address}}&apiKey={{apiKey}}',
    },
    disabledWallets: ['coinbase', 'NONE', 'tally', 'trust'],
    ensRegistryAddress: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
    features: [
      FEATURES.CONTRACT_INTERACTION,
      FEATURES.DOMAIN_LOOKUP,
      FEATURES.EIP1271,
      FEATURES.EIP1559,
      FEATURES.ERC721,
      // FEATURES.RELAYING,
      FEATURES.SAFE_APPS,
      FEATURES.SAFE_TX_GAS_OPTIONAL,
      FEATURES.SPENDING_LIMIT,
      FEATURES.TX_SIMULATION,
    ],
    gasPrice: [
      {
        type: GAS_PRICE_TYPE.FIXED,
        weiValue: '240000000000',
      },
    ],
    publicRpcUri: {
      authentication: RPC_AUTHENTICATION.NO_AUTHENTICATION,
      value: 'https://rpc.ankr.com/eth_goerli',
    },
    rpcUri: {
      authentication: RPC_AUTHENTICATION.API_KEY_PATH,
      value: 'https://goerli.infura.io/v3/',
    },
    safeAppsRpcUri: {
      authentication: RPC_AUTHENTICATION.API_KEY_PATH,
      value: 'https://goerli.infura.io/v3/',
    },
    shortName: 'gor',
    theme: {
      textColor: '#ffffff',
      backgroundColor: '#4D99EB',
    },
  },
]
